export const certiDoesDisponiveis = [
  {
    icone: "fas fa-file-medical",
    titulo: "Cartão Nacional de Saúde",
    conteudo: "Ministério da Saúde",
    url: "https://meususdigital.saude.gov.br/perfil",
  },
  {
    icone: "fas fa-file-medical",
    titulo: "Carteira Nacional de Vacinação",
    conteudo: "Ministério da Saúde",
    url: "https://meususdigital.saude.gov.br/vacinas",
  },
  {
    icone: "fas fa-wheelchair",
    titulo: "Certidão de Pessoa com Deficiência",
    conteudo: "Ministério dos Direitos Humanos e da Cidadania",
    url: "https://meu.inss.gov.br/#/certificado-deficiencia",
  },
  {
    icone: "fa-solid fa-file-invoice",
    titulo: "Certificado de Cadastro Único",
    conteudo: "Ministério do Desenvolvimento Social e Combate à Fome",
    url: "https://cadunico.dataprev.gov.br/#/home",
  },
  {
    icone: "fa-solid fa-check-to-slot",
    titulo: "Certidão de Quitação Eleitoral",
    conteudo: "Tribunal Superior Eleitoral",
    url: "https://www.tse.jus.br/servicos-eleitorais/certidoes/certidao-de-quitacao-eleitoral",
  },
  {
    icone: "fa-solid fa-file-invoice",
    titulo: "Certificado de Antecedentes Criminais",
    conteudo: "Polícia Federal",
    url: "https://servicos.pf.gov.br/epol-sinic-publico/",
  },
  {
    icone: "fa-solid fa-landmark",
    titulo: "Certidão Negativa de Débitos",
    conteudo: "Receita Federal",
    url: "https://solucoes.receita.fazenda.gov.br/Servicos/certidaointernet/Pf/EmitirPGFN",
  },
  {
    icone: "fa-solid fa-user",
    titulo: "Comprovante de Situação Cadastral no CPF",
    conteudo: "Receita Federal",
    url: "https://cav.receita.fazenda.gov.br/autenticacao/login/index/5090",
  },
  {
    icone: "fa-solid fa-file-invoice",
    titulo: "Consultar CNPJ",
    conteudo: "Receita Federal",
    url: "https://solucoes.receita.fazenda.gov.br/Servicos/cnpjreva/Cnpjreva_Solicitacao.asp",
  },
];
