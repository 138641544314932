import {
  DivCartaoSuperior,
  TextoNomeCidadao,
  TituloDocumento,
} from "../styles";
import { CartaoSuperiorCPF } from "./CartaoSuperiorCPF";

export function ComponenteDivCartaoSuperior(props) {
  if (props.tituloDocumento === "CPF") {
    return (
      <CartaoSuperiorCPF
        imagemDocumento={props.imagemDocumento}
        corFonte={props.corFonte}
        usuario={props.usuario}
        tituloDocumento={props.tituloDocumento}
        nomeSocial={props.nomeSocial}
      />
    );
  }

  return (
    <DivCartaoSuperior imagemDocumento={props.imagemDocumento}>
      <TituloDocumento corFonte={props.corFonte}>
        {props.tituloDocumento}
      </TituloDocumento>
      <TextoNomeCidadao corFonte={props.corFonte}>
        {props.usuario.nome}
      </TextoNomeCidadao>
    </DivCartaoSuperior>
  );
}
