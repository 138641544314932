import React from 'react';
import { DivCard, DivTag, TextoTitulo, TextoConteudo } from './styles';
import {isMobile} from 'react-device-detect';

export function Tag (props) {
    const tag = props.tag;

    if (isMobile) {
        return (
            <TagBody tag={tag}/>
        )
    }

    return (
        <DivTag>
            <div className="col d-flex justify-content-end">
                <TagBody tag={tag}/>
            </div>
        </DivTag>
        )
}

function TagBody (props) {
    const tag = props.tag;
    const tipoTag = tag && tag.tipo ? tag.tipo : 'success';
    
    return (
        <>
            {tag && tag.exibir &&
                <div className={`br-tag count small justify-content-end ${tipoTag}`}>
                    <span>{tag.conteudo}</span>
                </div>                            
            }
            {(!tag || !tag.exibir) && 
                <span>&nbsp;</span>
            }
        </>
    )
}

export default function Card (props) {
    const tag = props.tag;

    if(isMobile) {
        return (
            <>
                <div className="d-flex mb-1" onClick={props.onClick}>
                    <div className="m-half p-1">
                        <i className={props.icone} aria-hidden="true" style={{ fontSize: 25, color:'#0c326f'}}/>
                    </div>
                    <div className="m-half p-1 flex-fill">
                        <TextoTitulo  style={{ color:'#0c326f' }}>{props.titulo}                        
                        </TextoTitulo>                        
                    </div>
                    
                    <div className="m-half p-1 d-flex justify-content-end">
                        <Tag tag={tag}/>  
                        <i className="icon fas fa-chevron-right" aria-hidden="true" style={{color:'#D5D1D1'}}/>
                    </div>
                   

                </div>
            </>
        )
    }
 
   
    return (
        <div className="divPainel">
            <DivCard className="br-card" onClick={props.onClick}>
                <Tag tag={props.tag}/>                
                <div className="card-header">
                    <div>
                        <div>
                            <div>                               
                                <button className="br-button circle" type="button" aria-label="Ícone ilustrativo">
                                <i aria-hidden="true"  className={props.icone} style={{ fontSize: 25, color:'#0c326f' }}/>                                    
                                </button>
                            </div>
                            <TextoTitulo alinhamento='center' style={{ color:'#0c326f' }}>{props.titulo}</TextoTitulo>
                        </div>
                        
                    </div>
                </div>
                <div className="card-content" style= {{padding: '14px 0px 0px 0px'}}>      
                    <TextoConteudo style={{ color:'#0c326f',fontSize: '12px' }}>{props.conteudo}</TextoConteudo>
                    </div>              
            </DivCard>
        </div>
    )
}