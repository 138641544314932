import React, {Fragment} from 'react';
import NivelConta from '../NivelConta';
import BotaoAumentarNivelConta from '../BotaoAumentarNivelConta';

export default function CardAumentarNivelConta (props) {

    return (
        <Fragment>
            <div className="br-card mb-0" style={{maxWidth: '340px'}}>
                <div className="card-header">
                    <div className="d-flex">
                        <div className="text-weight-semi-bold text-up-02">Nível da Conta</div>
                    </div>
                    <span className="br-divider my-3"></span>
                </div>
                <div className="card-content">
                    <NivelConta/>
                    <br/>
                    <div className="row">
                        <div className="col-12">
                            <p>Aumente o nível da sua conta para acessar sua carteira de documentos, assinar documentos digitais e outras funcionalidades do aplicativo gov.br.</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer" style={{height: '40px'}}>
                    <div className="text-right">
                        <BotaoAumentarNivelConta destaque='primary'/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}