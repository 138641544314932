const config = {
	govbrUrl : process.env.REACT_APP_ACESSO_GOV_URL || 'https://sso.staging.acesso.gov.br', 
	govbrRedirectUri : process.env.REACT_APP_ACESSO_GOV_URL_RETORNO || 'http://localhost:3000/login',
	govbrClientId : process.env.REACT_APP_ACESSO_GOV_CLIENT_ID || 'h-conectagov.estaleiro.serpro.gov.br',
	govbrLogoutUrl : process.env.REACT_APP_ACESSO_GOV_LOGOUT_URL || 'http://localhost:3000/',
	govbrContasDadosPessoaisUrl : process.env.REACT_APP_ACESSO_GOV_CONTAS_DADOS_PESSOAIS_URL || 'https://contas.staging.acesso.gov.br/alteracao_cadastro',
	govbrContasPrivacidadeUrl : process.env.REACT_APP_ACESSO_GOV_CONTAS_PRIVACIDADE_URL || 'https://contas.staging.acesso.gov.br/privacidade',
	govbrVincularEmpresasUrl : process.env.REACT_APP_VINCULAR_EMPRESAS_URL || 'https://empresas.staging.acesso.gov.br/',
	govbrContasSegurancaUrl : process.env.REACT_APP_ACESSO_GOV_CONTAS_SEGURANCA_URL ||'https://contas.staging.acesso.gov.br/seguranca',
	govbrConfiabilidadesUrl : process.env.REACT_APP_ACESSO_GOV_CONFIABILIDADES_URL || 'https://confiabilidades.staging.acesso.gov.br',
	servicosSolicitadosUrl: process.env.REACT_APP_SERVICOS_SOLICITADOS_URL || 'http://solicitacao.servicos.gov.br',
	assinadorDigitalUrl : process.env.REACT_APP_ASSINADOR_DIGITAL_URL || 'https://assinador.iti.br',
	faqGovernoDigitalUrl: process.env.REACT_APP_FAQ_GOVERNO_DIGITAL_URL || 'https://www.gov.br/governodigital/pt-br/conta-gov-br/ajuda-da-conta-gov.br',
	potalLogadotempoFecharMensagensSegundos: process.env.REACT_APP_PORTAL_LOGADO_TEMPO_FECHAR_MENSAGENS_SEGUNDOS || 5,
	portalLogadoTempoVerificarNovasNotificacoes: process.env.REACT_APP_PORTAL_LOGADO_TEMPO_VERIFICAR_NOVAS_NOTIFICACOES_SEGUNDOS || 15,
	portalLogadoTempoAtualizarNotificacoesNaoLidas: process.env.REACT_APP_PORTAL_LOGADO_TEMPO_ATUALIZAR_NOTIFICACOES_NAO_LIDAS || 15,
	barraGovBrAmbiente: process.env.REACT_APP_BARRA_GOV_BR_AMBIENTE || 'producao',
	caixaPostalUrl: process.env.REACT_APP_CAIXA_POSTAL_URL || 'https://www.serpro.gov.br/'
}

export default config;