import { jsPDF } from "jspdf";
import QR from 'qrcode-base64';
import { cpfComMascara } from '../../scripts/textUtil';

const descricaoCPF = 'Cadastro de Pessoa Física';
const tituloCPF = 'CPF';
const orgaoEmissorCPF = 'Receita Federal';

const ativo = 'ATIVO';
const valido = 'VÁLIDO';
const dessincronizado = 'DESSINCRONIZADO';
const sincronizado = 'SINCRONIZADO';
const revogado = 'SUSPENSO';
const cancelado = 'CANCELADO';
const offline = 'OFFLINE';

export const getPdfCpf = (usuario, situacao, data_nascimento, documento) => {
    const imagemBrasao = require('../../images/brasao_azul.png');
    const logoReceita = require('../../images/logo_receita.png');
    const textoIdentificacao = 'Este documento digital não pode ser utilizado como documento de identificação.';
    const nomeSocial = documento.nomeSocial || usuario.nomeSocial

    const pdf = new jsPDF('p','mm','a4');
    pdf.setFont("helvetica");

    adicionaTituloDocumento (pdf, descricaoCPF, tituloCPF, orgaoEmissorCPF);
    
    /*
    Artifício para arredondar a borda da imagem: 
    Criado um retângulo arredondado com o mesmo background da imagem.
    Inserida a imagem ligeiramente menor dentro do retângulo 
    */
    pdf.setFillColor('#51b4ff');
    pdf.roundedRect(20, 40, 88, 58, 3, 3, 'F');
    //adicionaImagem(pdf, imagemBackgroundCPF, 22, 42, 84, 55);

    adicionaImagem(pdf, imagemBrasao, 25, 45, 10, 10);
    adicionaImagem(pdf, logoReceita, 73, 47, 30, 7.5);

    adicionaTexto(pdf, 'República Federativa do Brasil', 37, 48, {size:7, textColor: '#FFFFFF'});
    adicionaTexto(pdf, 'Ministério da Economia', 37, 51, {size:7, textColor: '#FFFFFF'});
    adicionaTexto(pdf, 'Secretaria da Receita Federal', 37, 54, {size:7, textColor: '#FFFFFF'});

    adicionaTexto(pdf, 'CPF', 25, 61, {size:9, textColor: '#FFFFFF', bold: true});
    adicionaTexto(pdf, cpfComMascara(usuario.cpf), 25, 65, {size:9, textColor: '#FFFFFF'});
    
    if(nomeSocial) {
        adicionaTexto(pdf, 'Nome social', 25, 71, {size:9, textColor: '#FFFFFF', bold: true});
        adicionaTexto(pdf, nomeSocial.toUpperCase(), 25, 75, {size:9, textColor: '#FFFFFF'});
    }

    const posicaoYNomeCivilLabel = nomeSocial ? 81 : 71
    const posicaoYNomeCivilValor = nomeSocial ? 85 : 75
    adicionaTexto(pdf, 'Nome civil', 25, posicaoYNomeCivilLabel, {size:9, textColor: '#FFFFFF', bold: true});
    adicionaTexto(pdf, usuario.nome, 25, posicaoYNomeCivilValor, {size:9, textColor: '#FFFFFF'});

    if(!usuario.isBronze) {
        if(data_nascimento) {
            const espacamentoDeLinha = 4
            const posicaoYLabel = nomeSocial ? 91 : 81
            const posicaoYValor = posicaoYLabel + espacamentoDeLinha
            adicionaTexto(pdf, 'Data de nascimento', 25, posicaoYLabel, { size:9, textColor: '#FFFFFF', bold: true });
            adicionaTexto(pdf, data_nascimento, 25, posicaoYValor, {size:9, textColor: '#FFFFFF'});
        }
        
        adicionaSituacaoDocumento(pdf, situacao.toUpperCase());
    }
    
    const qrcode = QR.drawImg(usuario.cpf, {
        typeNumber: 4,
        errorCorrectLevel: 'M',
        size: 500
    });
    adicionaQrcode(pdf, qrcode);

    adicionaTextoIdentificacao(pdf, textoIdentificacao);
    return  new Blob([ pdf.output('blob') ], {type : 'application/pdf;'});
}


export const getPdfDocumentoWallet = (documento) => {

//console.log(documento)
    const situacao = documento ? documento.situacao : null;
    const ehDocumentoIdentificacao = documento && documento.setup && documento.setup.identificacao;
    const girar_pdf = documento && documento.setup && documento.setup.girar_pdf;

    const regraQrcode = documento && documento.setup ? documento.setup.exibe_qrcode : null;
    const exibeQrcode = documento && documento.qrcode && (
                        (situacao === ativo && regraQrcode.ativo) ||
                        (situacao === valido && regraQrcode.valido) ||
                        (situacao === dessincronizado && regraQrcode.dessincronizado) ||
                        (situacao === sincronizado && regraQrcode.sincronizado) ||
                        (situacao === revogado && regraQrcode.revogado) ||
                        (situacao === cancelado && regraQrcode.cancelado) ||
                        (situacao === offline && regraQrcode.offline));

    const textoIdentificacao = documento.setup.texto_identificacao;
    const textoVerificacaoQrcode = documento && documento.setup && documento.setup.texto_qrcode && documento.setup.texto_qrcode.trim()  ? documento.setup.texto_qrcode : 'Verifique a autenticidade do QR code com o aplicativo Vio'

    const pdf = new jsPDF('p','mm','a4');
    pdf.setFont("helvetica");

    adicionaTituloDocumento (pdf, documento.descricao, documento.card.titulo, documento.orgao);
    
    const x = 5;
    let y = 40;
    const w = 68;
    const h = (documento.tipoDoc === 'TIE'? 132 : 112)
    const margem = 7;

   


    documento.imagens.forEach((imagem) => { 
        const imageData = `data:image/png;base64,${imagem.valor}`;
        if(girar_pdf === true) {
            adicionaImagemComBorda(pdf, imageData, x, y, w, h, 90);
        } else {
            adicionaImagemComBorda(pdf, imageData, x, y, h, w);
        }
        y = y + w + margem;
    })

    if(exibeQrcode && documento.qrcode) {
        const qrcode = `data:image/png;base64,${documento.qrcode}`;
        adicionaQrcode(pdf, qrcode);
    }

    if(ehDocumentoIdentificacao) {
        adicionaRetangulo(pdf, 140, 110, 45, 8, {fillColor: '#ffdf00', style: 'F'});
        adicionaTexto(pdf, 'DOCUMENTO DE IDENTIFICAÇÃO', 142, 115, {size: 7, textColor: '#20a433'}); 
    }

    adicionaSituacaoDocumento(pdf, situacao);

    adicionaTextoIdentificacao(pdf, textoIdentificacao);
    adicionaTextoVerificacaoQrcode(pdf, textoVerificacaoQrcode);

    return new Blob([ pdf.output('blob') ], {type : 'application/pdf;'});
}


export const getPdfCdi = (cdi, imagemCdi) => {

    if(imagemCdi && imagemCdi.startsWith('data:image')) {
        const pdf = new jsPDF('l','mm','a4');
        adicionaImagem(pdf, imagemCdi, 10, 10, 280, 189);
        return  new Blob([ pdf.output('blob') ], {type : 'application/pdf;'});
    } 
    return null
}

export const getPdfCnh = (cnh) => {

    //const textoIdentificacao = cnh.dadosCnh.mensagemTarjaCnh;
    const textoVerificacaoQrcode =  'Verifique autenticidade do QR Code com o app Vio.' //cnh.dadosCnh.mensagemTarjaQrCode;
    

    const pdf = new jsPDF('p','mm','a4');
    pdf.setFont("helvetica");

    adicionaTituloDocumento (pdf, 'Carteira Nacional de Habilitação', 'CNH', 'SENATRAN');
    
    const x = 20;
    let y = 40;
    const w = 102;
    const h = 68;
    const margem = 7;

    if(cnh.dadosCnh.indExibe === true) {
        const imagens = [];
        imagens.push(cnh.cnhFrente);
        imagens.push(cnh.cnhVerso);
        imagens.forEach((imagem) => { 
            const imageData = `data:image/png;base64,${imagem}`;
            adicionaImagemComBorda(pdf, imageData, x, y, w, h);
            y = y + h + margem;
        })
    }

    const qrcode = `data:image/png;base64,${cnh.qrCode}`;
    adicionaQrcode(pdf, qrcode);

    adicionaRetangulo(pdf, 140, 110, 45, 8, {fillColor: '#ffdf00', style: 'F'});
    adicionaTexto(pdf, 'DOCUMENTO DE IDENTIFICAÇÃO', 142, 115, {size: 7, textColor: '#20a433'}); 
    adicionaTexto(pdf, 'Este arquivo não pode ser utilizado', 140,125, {size: 9}); 
    adicionaTexto(pdf, 'como documento de habilitação.', 140,129, {size: 9}); 
   // adicionaTextoIdentificacao(pdf, textoIdentificacao);
    adicionaTextoVerificacaoQrcode(pdf, textoVerificacaoQrcode);

    return new Blob([ pdf.output('blob') ], {type : 'application/pdf;'});        
}

const adicionaTituloDocumento = (pdf, descricaoDocumento, tituloDocumento, orgaoEmissorDocumento) => {
    adicionaTexto(pdf, `${descricaoDocumento}  (${tituloDocumento}) - ${orgaoEmissorDocumento}`, 20, 20, {bold: true}); 
    adicionaTexto(pdf, 'QR CODE', 180, 20, {bold: true}); 
   // adicionaTexto(pdf, 'Compartilhado pelo aplicativo', 20, 27); 
   // adicionaTexto(pdf, 'gov.br', 67, 27, {bold: true}); 
}


const adicionaQrcode = (pdf, qrcode) => {
    if(! qrcode){
        return;
    }
    adicionaImagemComBorda(pdf, qrcode, 140, 40, 60, 60);
}

const adicionaSituacaoDocumento = (pdf, situacao) => {
    if(!situacao){
        return;
    }
    const corFundo = (situacao === revogado || situacao === cancelado) ?  '#ff0000' : '#888888';
    const larguraBorda = situacao.length * 2 + 2;
    adicionaRetangulo(pdf, 180, 110, larguraBorda, 8, {fillColor: corFundo, style: 'F'});
    adicionaTexto(pdf, situacao, 182, 115, {size: 7, textColor: '#FFFFFF'});
}

const adicionaTextoIdentificacao = (pdf, textoIdentificacao) => {
    adicionaTexto(pdf,  pdf.splitTextToSize(textoIdentificacao, 60), 140, 125, {size: 8});
}

const adicionaTextoVerificacaoQrcode = (pdf, textoVerificacaoQrcode) => {
    adicionaTexto(pdf,  pdf.splitTextToSize(textoVerificacaoQrcode, 60), 140, 150, {size: 8});
}

const adicionaImagem = (pdf, imageData, x, y, w, h) => {
    pdf.addImage({imageData, x, y, w, h})
}

const adicionaImagemComBorda = (pdf, imageData, x, y, w, h, rotation) => {
    let rotacao = 0;
    let x_final = x;
    let y_final = y;
    let w_borda = w;
    let h_borda = h;

    if(rotation && rotation === 90) {
        rotacao = rotation;
        x_final = x + w + w/2;
        y_final = y - w/2;
        w_borda = h;
        h_borda = w;
    }
    pdf.addImage({imageData, rotation: rotacao, x: x_final, y: y_final, w, h});
    adicionaRetangulo(pdf, x, y, w_borda, h_borda, {drawColor: '#e3e3e3'});
}

const adicionaTexto = (pdf, texto, x, y, props) => {
    if(! texto){
        return;
    }
    props && props.size ? pdf.setFontSize(props.size) : pdf.setFontSize(10);
    props && props.bold ? pdf.setFont(undefined, 'bold') : pdf.setFont(undefined, 'normal');
    props && props.textColor ? pdf.setTextColor(props.textColor) : pdf.setTextColor('#000000');
    pdf.text(texto, x, y);
}

const adicionaRetangulo = (pdf, x, y, w, h, props) => {
    const style = props && props.style ? props.style : 'S'; 
    props && props.drawColor ? pdf.setDrawColor(props.drawColor) : pdf.setDrawColor('#ffffff');
    props && props.fillColor ? pdf.setFillColor(props.fillColor) : pdf.setFillColor('#ffffff');
    pdf.rect(x, y, w, h, style);
}