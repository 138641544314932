import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import { escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice'
import { useHistory } from 'react-router-dom';

export default function ModalEmProcessamento (props) {
    const emProcessamento = useSelector((state) => state.aplicacaoStore.emProcessamento);
    const textoModalEmProcessamento = useSelector((state) => state.aplicacaoStore.textoModalEmProcessamento);
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const fecharModal = () => {
          if(emProcessamento) {
            dispatch(escondeEmProcessamento());
          }
        }

        // listener para ouvir mudanca de rota
        // sempre que a rota mudar, feche o
        const unregister = history.listen(() => {
            fecharModal();
        })

        return () => {
            unregister();
        };
    }, [dispatch, history, emProcessamento])

    return (
        <>
            <Modal visivel={emProcessamento} classModal="is-xsmall rounder-md" >
                <div className="container-fluid p-1 p-sm-4">
                    <div className="loading medium mb-4"/>
                    <span className="rotulo">{textoModalEmProcessamento}</span>
                </div>
            </Modal>
        </>
    )
}