import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import HeaderPagina from "../HeaderPagina";
import Card from "../Card";
import ModalTelaCustomizada from "../ModalTelaCustomizada";
import PdfViewer from "../PdfViewer";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import { certiDoesDisponiveis } from "./certidoes-disponiveis";

const Certidoes = () => {
  const history = useHistory();
  const usuario = useSelector((state) => state.usuarioStore.usuario);

  const caminhoBreadcrumb = [{ texto: "Baixar certidões" }];

  const [exibeCertidao, setExibeCertidao] = useState(false);
  const [certidao, _setCertidao] = useState(null);

  useEffect(() => {
    if (usuario.isCNPJ) {
      history.push("/");
    }
  }, [usuario.isCNPJ, history]);

  const fechaModalCertidao = useCallback(() => {
    setExibeCertidao(false);
  }, []);

  const abrirLinkEmNovaAba = React.useCallback((url) => {
    window.open(url, "blank");
  }, []);

  return (
    <>
      <Header />
      <div className="container-lg">
        <ModalTelaCustomizada
          visivel={exibeCertidao}
          onClose={fechaModalCertidao}
        >
          <PdfViewer arquivo={certidao} nomeArquivo="certidao.pdf" />
        </ModalTelaCustomizada>

        <HeaderPagina
          caminhoBreadcrumb={caminhoBreadcrumb}
          titulo="Baixar certidões"
        />

        <div className="cabecalho_painel">
          <p>Emita certidões públicas por meio do portal gov.br</p>
        </div>

        <div className="col">
          <div className={isMobile ? "" : "row"}>
            {certiDoesDisponiveis.map((certidao, i) => (
              <Card
                key={i}
                icone={certidao.icone}
                titulo={certidao.titulo}
                conteudo={certidao.conteudo}
                onClick={() => abrirLinkEmNovaAba(certidao.url)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
export default Certidoes;
