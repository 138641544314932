import React, {Fragment, useState} from 'react';
import CardDocumento from '../CardDocumento';
import { cpfComMascara } from '../../../scripts/textUtil';

import ModalConfirmacao from '../../ModalConfirmacao';
import CardAumentarNivelConta from '../../CardAumentarNivelConta';
import config from '../../../config';
import { EstiloContainerDocumento } from '../CardDocumento/styles';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';

export default function CarteiraBronze (props) {
    const documentos = props.documentos;
    const [exibeModal, setExibeModal] = useState(false);
    const corpoModal = <p>A carteira de documentos é uma funcionalidade disponível para contas <strong>prata</strong> ou <strong>ouro</strong>. Para acessar os documentos, <strong>aumente o nível</strong> da sua conta gov.br.</p>;
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    
    return (
        <Fragment>
             <ModalConfirmacao visivel={exibeModal} 
                corpo={corpoModal}
                confirmarLabel = 'Aumentar Nível'
                onConfirmar={() => {
                        setExibeModal(false);
                        return window.location.assign(config.govbrConfiabilidadesUrl);
                    }
                }
                cancelarLabel = 'Cancelar'
                onCancelar={()=> setExibeModal(false)}
            />
            <div className='cabecalho_painel'>
                <p>Aqui você visualiza os documentos digitais que tiver adicionado à sua Carteira, utilizando o <b>aplicativo gov.br</b>.</p>
                <p>Para visualizar outros documentos, além do CPF, você precisa aumentar o nível da sua conta gov.br, clicando em “Aumentar nível”, no box abaixo.</p>
                <br/>
                <p>Utilize o aplicativo gov.br para acessar mais funcionalidades como favoritar, compartilhar ou excluir documentos digitais da sua carteira.</p>
            </div>

            <div className="row align-items-center">
                <div className={`col-12 ${styles["col-i-6"]} col-md-8 col-lg-8`}>
                    {documentos &&  documentos.map((documento, index) => 
                                <EstiloContainerDocumento key={index}>
                                    <CardDocumento imagemDocumento={`data:image/png;base64,${documento.card.background}`} 
                                        tituloDocumento={documento.card.titulo.toUpperCase()}
                                        numeroIdentificacao={documento.tipoDoc === 'CPF' ? cpfComMascara(documento.card.identificacao.valor) : documento.card.identificacao.valor}
                                        labelIdentificacao={documento.card.identificacao.label}
                                        nomeDocumento={documento.descricao} 
                                        nomeOrgao={documento.orgao}
                                        corFonte={documento.tipoDoc !== 'CPF' ? '#000000' : '#ffffff'}
                                        nomeSocial={documento.nomeSocial || usuario.nomeSocial}
                                        onClick={() => setExibeModal(true)}/>
                                </EstiloContainerDocumento>
                            )
                    }

                </div>
                <div className={`col-12 col-md-4 ${styles["col-i-6"]} col-lg-4`}>
                    <CardAumentarNivelConta />
                </div>
            </div>

        </Fragment>
    )
}