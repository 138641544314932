import React from 'react';
import { useSelector} from 'react-redux';
import { DivCard, DivCartaoInferior, TextoLabelIdentificacao, TextoNumeroIdentificacao, TextoLabelSituacao, TextoSituacao, TextoNomeEmissorDocumento, TextoNomeDocumento as TextoDescricaoDocumento, DivCartao} from './styles';
import { ComponenteDivCartaoSuperior } from './ComponenteCardSuperior/ComponenteDivCardSuperior';

export default function CardDocumento (props) {
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    
    return (
        <DivCard className="br-card" onClick={props.onClick}>
            <div className="card-content">
                <DivCartao>
                    <ComponenteDivCartaoSuperior 
                        imagemDocumento={props.imagemDocumento}
                        corFonte={props.corFonte}
                        usuario={usuario}
                        tituloDocumento={props.tituloDocumento}
                        nomeSocial={props.nomeSocial}
                    />
                    <DivCartaoInferior imagemDocumento={props.imagemDocumento}>
                        <div className="row">
                            <div className="col">
                                <TextoLabelIdentificacao>{props.labelIdentificacao}</TextoLabelIdentificacao>
                            </div>
                            {props.labelSituacao &&
                                <div className="col">
                                    <TextoLabelSituacao>{props.labelSituacao}</TextoLabelSituacao>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col">
                                <TextoNumeroIdentificacao>{props.numeroIdentificacao}</TextoNumeroIdentificacao>
                            </div>
                            { props.situacao && 
                                <div className="col">
                                    <TextoSituacao>{props.situacao}</TextoSituacao>
                                </div>
                            }
                        </div>
                    </DivCartaoInferior>
                </DivCartao>
                <TextoNomeEmissorDocumento>{props.nomeOrgao}</TextoNomeEmissorDocumento>
                <TextoDescricaoDocumento>{props.nomeDocumento}</TextoDescricaoDocumento>
            </div>
        </DivCard>
    )
}